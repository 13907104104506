import ApplicationController from './application_controller';
import Fetchers from 'core/fetchers';

export default class extends ApplicationController {
  static targets = [
    'overModal', 'currentPhase',
    'password', 'passwordPhaseContent', 'passwordValidationButton', 'passwordErrorMessage',
    'mfaCode', 'codePhaseContent', 'codeValidationButton', 'codeErrorMessage',
    'phoneNumber', 'phonePhaseContent', 'setPhoneNumberButton', 'phoneErrorMessage',
    'sendCodeButton',
    'loginCodeForm'
  ];

  static values = {
    currentPhase: String,
    flow: String
  };

  static outlets = [];

  connect() {
    this.currentFlow = this.flowValue
  }

  initialize() {
  }

  validatePassword(e) {
    e.preventDefault();
    const password = this.passwordTarget.value;
    const errorMessage = this.passwordErrorMessageTarget;

    errorMessage.style.display = 'none';
    errorMessage.textContent = '';
  
    // Fetch the CSRF token from the page's meta tag
    const token = document.querySelector('meta[name="csrf-token"]').content;
  
    fetch('/two_factor_settings/validate_password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ password })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.nextPhase('password');
      } else {
        errorMessage.textContent = "Incorrect password";
        errorMessage.style.display = 'block'; // Show the error message
        return;
      }
    })
    .catch(error => {
      console.error('Error during password validation:', error);
      throw error;
    });
  }

  setPhoneNumber(e) {
    e.preventDefault();
    const phoneNumber = this.phoneNumberTarget.value.trim();
    const countryCode = document.querySelector('select[name="country_code"]').value;
    const errorMessage = this.phoneErrorMessageTarget;

    errorMessage.style.display = 'none';
    errorMessage.textContent = '';

    if (phoneNumber.length < 10) {
      errorMessage.textContent = "Phone number must be at least 10 digits";
      errorMessage.style.display = 'block';
      return;
    }
  
    const token = document.querySelector('meta[name="csrf-token"]').content;
  
    fetch('/two_factor_settings/set_phone_number', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        two_factor_setting: {
          phone_number: phoneNumber,
          country_code: countryCode
        }
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        this.phoneNumberValue = phoneNumber; // Store the phone number for later use
        this.nextPhase('phone');
      } else {
        errorMessage.textContent = "Failed to send code";
        errorMessage.style.display = 'block';
        return;
      }
    })
    .catch(error => {
      console.error('Error sending code:', error);
      throw error;
    });
  }

  showMaskedPhoneNumber() {
    if (this.phoneNumberValue) {
      const maskedPhoneNumber = this.maskPhoneNumber(this.phoneNumberValue);
      document.querySelector('#maskedPhoneNumber').innerText = maskedPhoneNumber;
    }
  }
  
  maskPhoneNumber(phoneNumber) {
    return `(***) ***-${phoneNumber.slice(-4)}`;
  }
  
  
  sendCode() {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const errorMessage = this.codeErrorMessageTarget;

    errorMessage.style.display = 'none';
    errorMessage.textContent = '';
  
    fetch('/two_factor_settings/send_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (!data.success) {
        errorMessage.textContent = "You have requested too many codes. Please try again later.";
        errorMessage.style.display = 'block';
        return;
      }
    })
    .catch(error => {
      console.error('Error sending code:', error);
    });
  }

  sendCodeLogin() {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const errorMessage = this.codeErrorMessageTarget;

    errorMessage.style.display = 'none';
    errorMessage.textContent = '';
  
    fetch('/sessions/send_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (!data.success) {
        errorMessage.textContent = "You have requested too many codes. Please try again later.";
        errorMessage.style.display = 'block';
        return;
      }
    })
    .catch(error => {
      console.error('Error sending code:', error);
    });
  }

  validateMFACode(e) {
    e.preventDefault();
    const code = this.mfaCodeTarget.value;
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const errorMessage = this.codeErrorMessageTarget;

    errorMessage.style.display = 'none';
    errorMessage.textContent = '';
  
    fetch('/two_factor_settings/validate_mfa_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ code })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        return; // Does nothing at the moment
      } else {
        errorMessage.textContent = "Incorrect code";
        errorMessage.style.display = 'block';
        return;
      }
    })
    .catch(error => {
      console.error('Error validating MFA code:', error);
      throw error;
    });
  }

  enableMFA() {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const code = this.mfaCodeTarget.value; // Get MFA code from the appropriate target
    const errorMessage = this.codeErrorMessageTarget;

    errorMessage.style.display = 'none';
    errorMessage.textContent = '';
    
    fetch('/two_factor_settings/enable_mfa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ two_factor_setting: { code } })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        window.location.reload(); // Refresh the page
      } else {
        errorMessage.textContent = "Failed to enable MFA";
        errorMessage.style.display = 'block';
        return;;
      }
    })
    .catch(error => {
      console.error('Error enabling MFA:', error);
      throw error;
    });
  }
  
  disableMFA() {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const code = this.mfaCodeTarget.value; // Get MFA code from the appropriate target
    const errorMessage = this.codeErrorMessageTarget;

    errorMessage.style.display = 'none';
    errorMessage.textContent = '';
    
    fetch('/two_factor_settings/disable_mfa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ two_factor_setting: { code } })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        window.location.reload(); // Refresh the page
      } else {
        errorMessage.textContent = "Failed to disable MFA";
        errorMessage.style.display = 'block';
        return;
      }
    })
    .catch(error => {
      console.error('Error disabling MFA:', error);
      throw error;
    });
  }
  
  // Show appropriate phase
  showPhase(phase) {
    this.changePhaseVisibility(phase, true);
    // Hide other phases
    const phases = ['password', 'phone', 'code'];
    phases.filter(p => p !== phase).forEach(p => this.changePhaseVisibility(p, false));
  }

  nextPhase(currentPhase) {
    switch (currentPhase) {
      case 'password':
        // Skip phone phase in disable flow
        if (this.currentFlow === 'enable') {
          this.showPhase('phone');
        } else if (this.currentFlow === 'disable') {
          this.sendCode();
          this.showPhase('code');
        }
        break;
      case 'phone':
        this.sendCode();
        this.showPhase('code');
        this.showMaskedPhoneNumber();
        break;
      case 'code':
        this.continue();
        break;
      default:
        this.showPhase('password');
        break;
    }
  }

  changePhaseVisibility(phase, show) {
    let phaseTarget;
  
    switch (phase) {
      case 'password':
        phaseTarget = this.passwordPhaseContentTarget;
        break;
      case 'phone':
          phaseTarget = this.phonePhaseContentTarget;
      break;
      case 'code':
        phaseTarget = this.codePhaseContentTarget;
        break;
      default:
        console.error('Invalid phase:', phase);
        return;
    }
  
    if (!phaseTarget) {
      console.warn(`Phase target for "${phase}" is not defined.`);
      return;
    }
  
    // Ensure phaseTarget is treated as an array
    if (phaseTarget instanceof NodeList || Array.isArray(phaseTarget)) {
      phaseTarget.forEach(outlet => {
        if (show) {
          outlet.classList.remove('hide');
          outlet.classList.add('show');
        } else {
          outlet.classList.add('hide');
          outlet.classList.remove('show');
        }
      });
    } else {
      // Handle single element case
      if (show) {
        phaseTarget.classList.remove('hide');
        phaseTarget.classList.add('show');
      } else {
        phaseTarget.classList.add('hide');
        phaseTarget.classList.remove('show');
      }
    }
  }  

  continue(e) {
    e.preventDefault()
  }

  abort(e) {
    e.preventDefault()
    this.containersDialogContainerOutlet.close();
  }

  closeDialogContainer() {
    this.containersDialogContainerOutlet.close();
  }

}
